import i18n from '@/i18n'
import { mapState } from 'vuex'
import Lset from 'lodash/set'
import Lmerge from 'lodash/merge'

import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import apiClient from '@/lib/unlogin/store/apiclient'
import { createNotification } from '@/lib/unnotificationsqueue'

import { RESPONSE_LEVEL } from '@/variables'
import Layout from '@/apps/core/components/Layout'
import sections from '../defaultNavigationDrawerSections'
import { flattenFormProps } from '@/apps/core/helpers/utils'

export default {
  name: 'PromoNoteValidationList',
  components: {
    Layout,
    CrudTable
  },
  data () {
    return {
      title: i18n.tc('promoNoteValidation.sectionName'),
      sections: sections,
      customOptions: { 'sortBy': ['id'], 'sortDesc': [true] },
      storeModule: 'promoNoteValidation',
      paramToSend: {
        note: null,
        status: null,
        allocated: null,
        promo_detachment: null,
        props: {
          kbd_type: null
        }
      },
      actionList: {
        delete: i18n.tc('PromoNoteValidation.deleteNoteLine')
      }
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig },
      loading: function (state) { return state[this.storeModule].loading }
    })
  },
  methods: {
    Lset,
    /**
   * Event handler when modifying a CrudTable's editable field. Redirects it to respective patch endpoint
   * @param value {string, number} new value
   * @param header {string} field edited
   * @param item {object} full instance of modified row
   * @returns {Promise<void>}
   */
    async onModifyField ({ value, header, item }) {
      let previousValue = this.Lget(item, header)
      let nestedField = header.split('.')
      try {
        if (nestedField.length === 1 || (nestedField.length === 2 && nestedField[0] === 'props')) {
          await this.onModifyNoteLine(header, value, item)
        } else if (nestedField.length > 1) {
          let model = nestedField[0]
          let attribute = nestedField.slice(1).join('.')
          await this.onModifyNested(attribute, value, model, item)
        }
      } catch (error) {
        this.Lset(item, header, previousValue)
      }
    },

    /**
   * Called when a NoteLine element is modified: calls NoteLine's patch endpoint
   * @param header same as onModifyField
   * @param value same as onModifyField
   * @param item same as onModifyField
   * @returns {Promise<void>}
   */
    async onModifyNoteLine (header, value, item) {
      try {
        let noteLineId = item.id
        let body = flattenFormProps(Lmerge(item, Lset({}, header, value)))
        body.promo = body.promo_detachment.promo.id
        body.props = item.props
        body.promo_detachment = body.promo_detachment.id
        body.note = body.note.id
        body.props.kbd_type = body.prop__kbd_type
        await apiClient.patch(`/note-lines/${noteLineId}/`, body)
        await this.$store.dispatch('addNotification', createNotification(this.$t('dialogs.savedNewValues'), RESPONSE_LEVEL.SUCCESS))
        await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    /**
   * Set the values onChanged items
   * @param header
   * @param value
   * @param item
   */
    async setValueToSend ({ header, value, item }) {
      let body = flattenFormProps(Lmerge(item, Lset({}, header, value)))
      this.paramToSend.note = body.note_line_note
      this.paramToSend.allocated = body.note_line_allocated
      this.paramToSend.status = body.note_line_status
      this.paramToSend.promo_detachment = body.id
      this.paramToSend.props.kbd_type = body.prop__kbd_base_type
      await this.sendData()
    },

    /**
   * In case all required params are completed, send the noteLine object.
   * @returns {Promise<void>}
   */
    async sendData () {
      if (this.paramToSend.allocated && this.paramToSend.status && this.paramToSend.note) {
        try {
          await apiClient.post(`/note-lines/`, this.paramToSend)
          this.$store.dispatch('addNotification', createNotification(this.$t('dialogs.savedNewValues'), RESPONSE_LEVEL.SUCCESS))
          await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
        } catch (error) {
          throw error
        }
      }
    },

    /**
   * Called when a nested element is modified (So far only Note): calls element's patch endpoint
   * @param attribute {string} parsed from header, refers to the models's modified attribute
   * @param value same as onModifyField
   * @param model {string} parsed from header, refers to the model whose attribute was modified (So far only note)
   * @param item same as onModifyField
   * @returns {Promise<void>}
   */
    async onModifyNested (attribute, value, model, item) {
      try {
        let instanceId = item[model].id
        let body = flattenFormProps(Lmerge(item[model], Lset({}, attribute, value)))
        body.props = item.note.props
        await apiClient.patch(`/${model}s/${instanceId}/`, body)
        await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
      } catch (error) {
        throw error
      }
    },
    async onDeleteNoteLine (item) {
      if (await this.$store.dispatch(`${this.storeModule}/deleteNoteLine`, item)) {
        await this.$store.dispatch(`${this.storeModule}/reloadItemList`)
        await this.$store.commit(`${this.storeModule}/cleanEditableValues`, item.id)
      }
    }
  }
}
